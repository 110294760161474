.backgrEffect_container {
  width:800px;
  height: 100%;
  position: fixed;
}

.backgrEffect {
  position: relative;
  top: 0%;
  width: 10px;
  height: 100%;
  margin: auto;
  left: 0;
  right: 0;
  background: #3e1e6800;
  -webkit-transform: translate3d(0,0,0);
  z-index: 0;
}

.backgrEffect span {
  width: 10px;
}

$particleSize: 15vmin;
$animationDuration: 10s;
$amount: 10;
.backgrEffect span {
  width: $particleSize;
  height: $particleSize;
  border-radius: $particleSize;
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-duration: $animationDuration;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  $colors: (
          #007599,
          #ff2500,
          #009eff,
          #99d000,
          #5eff00,
  );
  @for $i from 1 through $amount {
    &:nth-child(#{$i}) {
      $random-number: random(); // génère un nombre aléatoire compris entre 0 et 1
      $random-direction: if($random-number < 0.5, -1, 1); // transforme le nombre en -1 ou 1 en fonction de sa valeur
      color: nth($colors, random(length($colors)));
      top: random(100) * 1%;
      left: random(100) * 1%;
      animation-duration: (random($animationDuration * 10) / 10) * 1s + 15s;
      animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
      transform-origin: (random(50) - 25) * 1px (random(50) - 25) * 1px;
      $blurRadius: (random() + 0.5) * $particleSize * 0.5;
      $x: if(random() > 0.5, -1, 1);
      box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;

    }
  }
}
@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}
