

@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-video.css');

body {
  --black: #000000;
  --ash-black: #222;
  --white: #fafafa;
  --sky: #00ccff;
  --green: #22dddd;
  --blue: #1300ff;
  --blue_2: rgba(0, 32, 90, 0.83);
  --dusk: #6600ff;
  --purple: #9900ff;
  --pink: #ff0066;
  --red: #fe0222;
  --orange: #fd7702;
  --yellow: #ffbb00;

  --background: var(--blue_2);
  --accent: var(--orange);
  position: absolute;
  margin: auto;
  padding: 0;
  background-color: var(--background);
  color: var(--accent);
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  min-width: 800px;
  max-width: 1200px;
  left: 0;
  right: 0;
  align-items: center;
  width: 100%;
}

html, body {
  scroll-padding-left: calc(50vw - 50%); /* calcule la distance à partir de la gauche de la page pour centrer la barre de défilement */
}


#root {
  -webkit-transform: translate3d(0,0,0);
  z-index: 2;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

* {
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3 {
  font-family: sofia-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
}

h1 {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1.2;
  text-align: center;
  margin: 100px 0 40px;
}

h2 {
  font-weight: 400;
  margin: 50px 0 10px;
}

p {
  margin: 0 0 30px 0;
  font-size: 18px;
}

.footer_container {
  min-height: 10px; /* Définit la hauteur minimale du div à 100% de la hauteur de la fenêtre */
  position: sticky; /* Définit la position du div comme relative */
  width: 100%;
  bottom: 0;
  z-index: 10;
}

footer {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  line-height: 150px;
  -webkit-transform: translate3d(0,0,0);
  z-index: 30;
  margin: auto;
  width: 100%;
}

footer::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: 15px 15px;
  backdrop-filter: brightness(90%) blur(20px);
  -webkit-backdrop-filter: brightness(90%) blur(40px);
  -moz-backdrop-filter: brightness(90%) blur(40px);
  mask-image: repeating-radial-gradient(
          circle at 50% 50% center,
          var(--background),
          var(--background) 30px,
          rgba(255, 255, 255, 0.3) 20.5px,
          rgba(255, 255, 255, 0.3) 21px
  );
  -webkit-mask-image: repeating-radial-gradient(
          circle at 50% 50%,
          rgba(255, 255, 255, 0.3),
          var(--background) 17px,
          rgba(0, 0, 0, 0.82) 20.5px,
          rgba(255, 255, 255, 0.3) 21px
  );
  -webkit-transform: translate3d(0,0,0);
  z-index: 30;
  min-width: 100%;
}

footer svg {
  margin-right: 20px;
}

footer a {
  text-decoration: none;
  color: var(--accent);
  font-size: 20px;
  -webkit-transform: translate3d(0,0,0);
  z-index: 40;
}

code {
  font-family: input-mono, monospace;
  font-weight: bold;
  font-style: normal;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: var(--background);
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
  background: var(--accent);
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: #fff3;
}

.refresh {
  padding: 5px;
  position: absolute;
  border: 1px dotted var(--accent);
  border-radius: 5px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.refresh path {
  fill: var(--accent);
}

.box {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: var(--accent);
  margin: 0;
}

.menu,.menuForName {
  margin: auto;
  display: flex;
  filter: drop-shadow(1px 1px 1px #5116a9);
  width: 300px;
  flex-direction: column;
  align-items: center;
}

.menuForName {
  position: relative;
  top: -150px;
  height: 100px;
  width: 400px;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transform: translate3d(0,0,0);
  z-index: 20;
}
.menuForName:hover {
  filter: brightness(0.9);
  filter: drop-shadow(2px 4px 6px black);
}

ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: var(--accent);
  width: fit-content ;
}

li {
  color: var(--background);
  display: block;
  transform-origin: -20px 50%;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 5px 30px;
  white-space: nowrap;
}

button {
  -webkit-appearance: button;
  background: var(--accent);
  color: var(--background);
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button path {
  fill: var(--background);
}

input {
  accent-color: var(--accent);
}

.controls {
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-bottom: 50px;
  align-items: center;
}

label {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

input[type="number"] {
  border: 0;
  border-bottom: 1px dotted var(--accent);
  color: var(--accent);
  margin-left: 10px;
  background: var(--background);
  width: 50px;
}

input[type="number"]:focus {
  outline: none;
  border-bottom: 2px solid var(--accent);
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.example {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.item {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: var(--accent);
  color: var(--background);
  font-size: 240px;
  line-height: 260px;
  font-weight: 700;
  border-radius: 30px;
}

.LightGApp {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 150px 0 200px 0;
  max-width: 900px;
  margin: auto ;
}

.LightGApp a {
  transition: transform 1s ease-in-out;
  padding: 40px 0;
}

.LightGApp a img {
  min-width:300px;
}


.lien_nav a {
  font-size: 20px;
}
.lien_nav a:link {
  color: #000000; /* couleur du lien non visité */
  font-weight: bold;
  text-decoration: none; /* soulignement du lien non visité */
}

.lien_nav a:visited {
  color: #000000; /* couleur du lien non visité */
  font-weight: bold;
  text-decoration: none;
}

.lien_nav a:hover {
  color: #129908; /* couleur du lien lorsque la souris est dessus */
  font-weight: bold;
  text-decoration: none; /* pas de soulignement lorsque la souris est dessus */
}

.lien_nav a:active {
  color: #9fd49f; /* couleur du lien lorsque l'utilisateur est en train de cliquer dessus */
  font-weight: bold;
  text-decoration: none;
}
#lg-next-1, #lg-prev-1{
  max-width: 50px;
  min-width: 35px;
}

.lg-next, .lg-prev {
  color: #bb7a0a;
  font-size: 30px;
  background-color: rgb(0 0 0 / 74%);
}

#my_gallery{
  -webkit-transform: translate3d(0,0,0);
  z-index: 1;
  position: relative;
}


.play_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%) translate3d(0,0,0);
  -webkit-transform: translate(-50%, -50%) translate3d(0,0,0);
}

/* la prop sci-dessous permet de fixer le triangle noir du lecteur vidéo pour ne pas avoir de bugs
ce tringle noir apparrait pour la premiere fois dans .lg_video::before*/
.lg_video {
  position: relative;
}

.lg_video img, .lg_image img{
  border-radius: 20px;
  transition: transform 0.4s ease-out;
  transition-delay: 0.2s;
  transform:translate3d(0,0,0);
  -webkit-transform: translate3d(0,0,0);
  z-index: 0;
}

.lg_video:hover img, .lg_image:hover img{
  border-radius: 20px;
  transform: translate3d(0,0,0) scale(1.3);
  -webkit-transform: translate3d(0,0,0) scale(1.3);
  z-index: 30;
}

.lg_video::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath d='M6 4v40l36-20z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 120px 120px;
  background-position: center;
  opacity: 0.7;
  transition: opacity 0.2s,brightness .5s, transform 0.2s;
  transition-delay: 0.2s, 0.2s, 0.2s;
  transform: translate3d(0,0,0);
  -webkit-transform: translate3d(0,0,0);
  z-index: 5;
  max-width: 320px;
  min-height: 90%;
  filter: contrast(100%) blur(2px);
}

.lg_image::before{
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: opacity 0.2s ease-in-out,brightness .5s, transform 0.2s ease-out;
  transition-delay: 0.05s, 0.05s, 0.05s;
  transform: translate3d(0,0,0);
  -webkit-transform: translate3d(0,0,0);
  z-index: 5;
  opacity: 0.5;
  background-position: center;
}

.lg_video:hover::before, .lg_image:hover::before {
  opacity: 1;
  filter: contrast(100%) blur(0px);
  transform: translate3d(0,0,0) scale(1.3);
  -webkit-transform: translate3d(0,0,0) scale(1.3);
  z-index: 20;
}

.lg_video:hover, .lg_image:hover {
  transform: translate3d(0,0,0);
  -webkit-transform: translate3d(0,0,0);
  z-index: 30;
}

.lg_thumbnail_img {
  max-width: 320px !important;
  height: auto;
}

.backgrEffect, .my_gallery, body {
  min-width: 800px;
}



.div_social{
  font-weight: bold;
  text-align:center;
  position: relative;
  margin: auto;
  color: black;
  cursor: pointer;
  text-decoration: none;
}














